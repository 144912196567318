//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      typeData: [],
      langData: [],
      form: this.$form.createForm(this),
      scriptDetail: []
    }
  },
  methods: {
    // 初始化方法
    detail (record) {
      this.visible = true
      this.getTypeData()
      this.getLangData()
      this.scriptDetail = record
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            content: record.content
          }
        )
      }, 100)
    },

    typeFilter (type) {
      // eslint-disable-next-line eqeqeq
      const values = this.typeData.filter(item => item.code == type)
      if (values.length > 0) {
        return values[0].value
      }
    },
    langFilter (lang) {
      // eslint-disable-next-line eqeqeq
      const values = this.langData.filter(item => item.code == lang)
      if (values.length > 0) {
        return values[0].value
      }
    },

    /**
     * 獲取類型下拉框數據
     */
    getTypeData () {
      sysDictTypeDropDown({ code: 'script_type' }).then((res) => {
        this.typeData = res.data
      })
    },

    /**
     * 獲取語言下拉框數據
     */
    getLangData () {
      sysDictTypeDropDown({ code: 'script_language_type' }).then((res) => {
        this.langData = res.data
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
